import logo from './logo.svg';
import './App.css';
import { Heading } from 'evergreen-ui'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <div>
            <Heading size={900} color={'#e4e7eb'}>Lassoing Rhetoric v1.1</Heading>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
